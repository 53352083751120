import React, { useState, useContext, useEffect } from "react"
import TCPA from "../components/TCPA"
import User from "../components/User"
import MultiStepNav from "../components/multistep-form/MultiStepNav"
import ProspectContext from "../components/ProspectContext.js"
import CurrentContext from "../components/CurrentContext.js"
import { useForm } from "react-hook-form"
import axios from "axios"
import Session321 from "../components/Session321"
import { Helmet } from "react-helmet"

const LambdaForm = () => {
  const { prospect, updateProspect } = useContext(ProspectContext)
  const { current, setCurrent } = useContext(CurrentContext)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  useEffect(() => {
    let skipButton = document.getElementById("skip_button")
    if (!skipButton) {
    } else {
      skipButton.style.display = "none"
    }
  })

  const onSubmit = (e, data, updateHiddenFields) => {
    //e.preventDefault()

    console.log(prospect)
    alert("Submitted: ", data)
    // axios
    //   .post(
    //     "https://getform.io/f/422c37b3-61da-4aaf-8f2d-65e174e26673",
    //     prospect
    //   )
    //   .then(function (response) {
    //     console.log(prospect)
    //     console.log(response.status)
    //     if (response.status === 200) {
    //       setCurrent(5)
    //     } else {
    //       setCurrent(5)
    //     }
    //   })
    //   .catch(function (error) {
    //     setCurrent(5)
    //     console.log(
    //       "There was an error submitting your request. Please contact us if the problem persists."
    //     )
    //   })
  }

  return (
    <div id="step_four">
      <div className="multistep-heading">
        <p>Contact Information</p>
      </div>
      <div className="flex-box">
        <div className="flex-horizontal">
          <div className="flex-vertical">
            <form onSubmit={handleSubmit(onSubmit)}>
              <input
                type="text"
                name="first_name"
                placeholder="First Name*"
                value={prospect.first_name}
                {...register("first_name", {
                  required: true,
                  maxLength: 20,
                  pattern: /^[A-Za-z]+$/i,
                })}
                onChange={updateProspect}
              ></input>
              <p className="error-message">
                {errors.first_name?.type === "required" &&
                  "First name is required"}
              </p>
              <input
                type="text"
                name="last_name"
                placeholder="Last Name*"
                value={prospect.last_name}
                {...register("last_name", { pattern: /^[A-Za-z]+$/i })}
                required
                onChange={updateProspect}
              ></input>
              <p className="error-message">
                {errors.last_name && "Last name is required"}
              </p>
              <input
                type="email"
                name="email"
                placeholder="Email*"
                value={prospect.email}
                {...register("email", {
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                })}
                required
                onChange={updateProspect}
              ></input>
              <p className="error-message">
                {errors.email && "Valid email is required"}
              </p>
              <input
                type="tel"
                name="callback_number"
                placeholder="Callback Number*"
                value={prospect.callback_number}
                // pattern="(?:(?:(\s*\(?([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\)?\s*(?:[.-]\s*)?)([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})"
                maxLength={14}
                minLength={10}
                required
                {...register("callback_number")}
                onChange={updateProspect}
              ></input>
              <p className="error-message">
                {errors.callback_number && "Callback number name is required"}
              </p>
              <p id="footnote">*Required field</p>
              <p id="error_message"></p>
              <User />
              {/* <div className="disclaimer">
                <input
                  type="hidden"
                  name="tcpa"
                  {...register("tcpa")}
                  onChange={updateProspect}
                />
                <TCPA />
              </div> */}
              <div className="button-container">
                <button
                  className="request-quote form-submit-conversion"
                  type="submit"
                >
                  Start Quote
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <MultiStepNav nextStep={4} prevStep={0} />
    </div>
  )
}

export default LambdaForm
